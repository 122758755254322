import { FC } from 'react'
import s from './PlayingField.module.scss'
import classNames from 'classnames'
import History from './History/History'
import { GameResult, IRoundResult } from '../Game'
import { svgIcons } from '../../../assets/svgIcons'

const PlayingField: FC<IProps> = ({
    gameResult,
    odd,
    history,
    number,
    changeValue,
    isAutobetRunning,
    marketType,
}) => {
    return (
        <div className={s.wrapper}>
            <History results={history} className={s.history} />
            <div className={s.rangeWrapper}>
                <div className={s.range}>
                    <div className={s.desc}>
                        <div className={s.item}>0{svgIcons.polygon}</div>
                        <div className={s.item}>25{svgIcons.polygon}</div>
                        <div className={s.item}>50{svgIcons.polygon}</div>
                        <div className={s.item}>75{svgIcons.polygon}</div>
                        <div className={s.item}>100{svgIcons.polygon}</div>
                    </div>
                    <div className={s.slider}>
                        <div className={s.inputWrapper}>
                            <input
                                type="range"
                                id="range"
                                className={classNames(
                                    s.input,
                                    {[s.input_over]: marketType},
                                )}
                                min={0}
                                max={100}
                                value={number}
                                onChange={e =>
                                    changeValue(
                                        'number',
                                        number,
                                        e.target.value
                                    )
                                }
                                disabled={isAutobetRunning}
                            />
                            <label
                                className={s.label}
                                style={{
                                    left: `calc(${number}%)`,
                                    transform: `translateX(-${number}%)`
                                }}
                            />
                        </div>

                        <div className={s.cubeWrapper}>
                            <div
                                className={classNames(
                                    s.cube,
                                    {[s.win]: gameResult === 'win'},
                                    {[s.lose]: gameResult === 'lose'},
                                )}
                                style={{left: `calc(-25px + ${odd}%)`}}
                            >
                                <span>{odd.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlayingField

interface IProps {
    gameResult: GameResult
    odd: number
    history: IRoundResult[]
    number: string
    changeValue: (
        name: 'amount' | 'number' | 'numberOfRounds',
        prevValue: string,
        newValue: string | ((prev: string) => string)
    ) => void
    isAutobetRunning: boolean
    marketType: boolean
}