import {useCallback, useContext} from 'react'
import s from './Footer.module.css'
import { svgIcons } from '../../../assets/svgIcons'
import classNames from 'classnames'
import GameConfigContext from '../../../context/GameConfigContext'
import PopupsOpenContext, { IPopups } from '../../../context/PopupsContext'
import OldHashAndKeyContext from '../../../context/OldHashAndKeyContext'

const Footer = () => {
    const [, setPopupsOpen] = useContext(PopupsOpenContext)

    const [gameConfig, changeGameConfig] = useContext(GameConfigContext)

    const changeIsMute = useCallback((value: boolean) => {
        localStorage.setItem(process.env.REACT_APP_MUTE_KEY ?? '', JSON.stringify(value))
        changeGameConfig('isMute', value)
    }, [])

    const changeIsTurboMode = useCallback((value: boolean) => {
        localStorage.setItem(process.env.REACT_APP_TURBO_MODE_KEY ?? '', JSON.stringify(value))
        changeGameConfig('isTurboMode', value)
    }, [])

    const openPopup = (popup: keyof IPopups) => {
        setPopupsOpen(prev => ({
            ...prev,
            [popup]: true,
        }))
    }

    const [oldHashAndKey] = useContext(OldHashAndKeyContext)

    return (
        <div className={s.footer}>
            <button
                className={classNames(s.btn, !gameConfig.isMute && s.btn_active)}
                onClick={() => changeIsMute(!gameConfig.isMute)}
            >
                {svgIcons.footer.sound}
            </button>
            <button
                className={classNames(s.btn, gameConfig.isTurboMode && s.btn_active)}
                onClick={() => changeIsTurboMode(!gameConfig.isTurboMode)}
            >
                {svgIcons.footer.boost}
            </button>
            <button className={s.btn} onClick={() => openPopup('rules')}>
                {svgIcons.footer.info}
            </button>
            <button
                className={classNames(s.btn, {[s.btn_active]: oldHashAndKey})}
                onClick={() => openPopup('provablyFairness')}
                disabled={!oldHashAndKey}
            >
                {svgIcons.footer.security}
            </button>
        </div>
    )
}

export default Footer