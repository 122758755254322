import { FC } from 'react'
import classNames from 'classnames'
import s from './History.module.css'
import { IRoundResult } from '../../Game'

const History: FC<IProps> = ({ results, className }) => {
    return (
        <div className={classNames(s.results, className)}>
            {results
                .slice(0, 10)
                .reverse()
                .map(round => (
                    <button
                        key={Date.now().toString() + round.odd}
                        className={classNames(
                            s.results__item,
                            round.status && s.results__item_green,
                        )}
                    >
                        {round.odd}
                    </button>
                ))
            }
        </div>
    )
}

export default History

interface IProps {
    results: IRoundResult[]
    className?: string
}
