import {FC, useCallback, useContext, useEffect, useState} from 'react'
import s from './Control.module.scss'
import Button from '../../Button/Button'
import Input from '../../Input/Input'
import Checkbox from '../../Checkbox/Checkbox'
import InitialStateContext from '../../../context/InitialStateContext'
import { checkCurrencyCode } from '../../../utils/checkCurrencyCode'
import {MarketTypes} from "../Game";
import {svgIcons} from "../../../assets/svgIcons";
import {useTranslation} from "../../../hooks/useTranslation";

const Control: FC<IProps> = ({
     amount,
     setAmount,
     multiplier,
     setMultiplier,
     number,
     setNumber,
     isAutobet,
     setIsAutobet,
     numberOfRounds,
     setNumberOfRounds,
     spin,
     runAutobet,
     isAutobetRunning,
     isGame,
     marketType,
     setMarketType,
     changeValue,
}) => {
    const { t } = useTranslation()
    const [initialState] = useContext(InitialStateContext)

    const changeMarketType = useCallback(
        (prevValue: boolean, newValue: boolean | ((prev: boolean) => boolean)) => {
            if (typeof newValue !== 'boolean') {
                newValue = newValue(prevValue)
            }

            setMarketType(newValue)
            localStorage.setItem(process.env.REACT_APP_MARKET_TYPE_KEY as string, JSON.stringify(newValue))
        },
        [],
    )

    /* Bet amount */
    const divideAmountValidation = (amount: string): string => {
        if (+amount / 2 >= initialState?.limits?.minBet) {
            return (+amount / 2).toFixed(initialState?.currencyPrecision).toString()
        }

        return amount
    }

    const multiplyAmountValidation = (amount: string): string => {
        if (+amount * 2 <= initialState?.limits?.maxBet) {
            return (+amount * 2).toFixed(initialState?.currencyPrecision).toString()
        }

        return amount
    }

    const amountActions = [
        { text: '½', action: () => changeValue('amount', amount, divideAmountValidation(amount)) },
        { text: '2×', action: () => changeValue('amount', amount, multiplyAmountValidation(amount)) },
        { text: t('MAX'), action: () => changeValue(
                'amount',
                amount,
                initialState?.limits?.maxBet.toFixed(initialState?.currencyPrecision),
        )},
    ]
    /* ----------- */

    /* Multiplier */

    useEffect(() => {
        let res = 0

        if (!marketType) {
            res = 1 / ((100 * +number) / 10000) * 0.98
        } else {
            res = 1 / ((10000 - 100 * +number) / 10000) * 0.98
        }

        setMultiplier(res.toFixed(2) + 'x')
    }, [number, marketType])
    /* ----------- */

    useEffect(() => {
        changeValue('number', number, (100 - +number).toString())
    }, [marketType])

    /* Win Chance  */
    const [winChance, setWinChance] = useState('0')

    useEffect(() => {
        let res = 0

        if (!marketType) {
            res = +number
        } else {
            res = 100 - +number
        }

        setWinChance(res.toFixed(2) + '%')
    }, [number, marketType])
    /* ----------- */


    /* Number Of Rounds */
    const numberOfRoundsActions = [
        { text: '20', action: () => setNumberOfRounds('20') },
        { text: '50', action: () => setNumberOfRounds('50') },
        { text: '100', action: () => setNumberOfRounds('100') },
    ]
    /* ----------- */

    useEffect(() => {
        if (!initialState) return

        const amount = localStorage.getItem(process.env.REACT_APP_AMOUNT_KEY as string)
        const num = localStorage.getItem(process.env.REACT_APP_NUMBER_KEY as string)
        const type = localStorage.getItem(process.env.REACT_APP_MARKET_TYPE_KEY as string)

        setAmount(
            amount ?? initialState?.limits?.minBet
                .toFixed(initialState?.currencyPrecision)
                .toString()
        )

        if (num) {
            setNumber(num)
        }

        setMarketType(type ? JSON.parse(type) : false)

    }, [initialState])

    return (
        <div className={s.control}>
            {!isAutobet && (
                <Button
                    onClick={() => spin(+amount, +number, +marketType)}
                    disabled={!Object.keys(initialState).length || isGame}
                    clickSoundExcept
                >
                    {t('BET')}
                </Button>
            )}
            {isAutobet && (
                <Button
                    onClick={runAutobet}
                    disabled={isAutobetRunning}
                    clickSoundExcept
                >
                    {t('STARTAUTOBET')}
                </Button>
            )}

            <Input
                value={amount}
                onChange={value =>
                    changeValue('amount', amount, value)
                }
                label={t('BETAMOUNT')}
                actions={amountActions}
                currency={checkCurrencyCode(initialState?.currencyCode)}
                min={initialState?.limits?.minBet}
                max={initialState?.limits?.maxBet}
                precision={initialState?.currencyPrecision}
                autoReplace={[
                    [/[^.\d]+/g, ""],
                    [/^([^\.]*\.)|\./g, '$1'],
                ]}
                readOnly={isAutobetRunning}
            />

            <div className={s.grid}>
                <Input
                    value={multiplier}
                    readOnly
                    onChange={() => {}}
                    label={t('MULTIPLIER')}
                />
                <div className={s.number}>
                    <Input
                        value={number}
                        onChange={value =>
                            changeValue('number', number, value)
                        }
                        label={!marketType
                            ? t('UNDER')
                            : t('OVER')
                        }
                        step={0.01}
                        min={0}
                        max={100}
                        precision={2}
                        autoReplace={[[/[^\d]/ig, '']]}
                        readOnly={isAutobetRunning}
                    />
                    <button
                        className={s.btn}
                        onClick={() => changeMarketType(marketType, !marketType)}
                        disabled={isAutobetRunning}
                    >
                        {svgIcons.random}
                    </button>
                </div>
                <Input
                    value={winChance}
                    readOnly
                    onChange={() => {}}
                    label={t('WINCHANCE')}
                />
            </div>

            <div className={s.autobet}>
                <Input
                    value={numberOfRounds}
                    onChange={value =>
                        changeValue('numberOfRounds', numberOfRounds, value)
                    }
                    label={t('NUMROUNDS')}
                    actions={numberOfRoundsActions}
                    max={10000}
                    autoReplace={[[/[^\d]/ig, '']]}
                    readOnly={isAutobetRunning}
                />
                <Checkbox
                    checked={isAutobet}
                    onChange={setIsAutobet}
                    className={s.checkbox}
                    clickSoundExcept
                >
                    {t('AUTOBET')}
                </Checkbox>
            </div>
        </div>
    )
}

export default Control

interface IProps {
    amount: string
    setAmount: (value: ((prev: string) => string) | string) => void
    multiplier: string
    setMultiplier: (value: ((prev: string) => string) | string) => void
    number: string
    setNumber: (value: ((prev: string) => string) | string) => void
    isAutobet: boolean
    setIsAutobet: (value: boolean) => void
    numberOfRounds: string
    setNumberOfRounds: (value: ((prev: string) => string) | string) => void
    spin: (amount: number, number: number, marketType: number) => void
    runAutobet: () => void
    isAutobetRunning: boolean
    isGame: boolean
    marketType: boolean
    setMarketType: (value: ((prev: boolean) => boolean) | boolean) => void
    changeValue: (
        name: 'amount' | 'number' | 'numberOfRounds',
        prevValue: string,
        newValue: string | ((prev: string) => string)
    ) => void
}
