import { createContext } from 'react'
import { Translations } from '../hooks/useTranslation'

const initGameConfig: IGameConfig = {
    isMute: false,
    isTurboMode: false,
    translations: null,
}

const GameConfigContext = createContext<GameConfigContextType>([initGameConfig, () => {}])

export default GameConfigContext

type GameConfigContextType = [
    IGameConfig,
    (param: keyof IGameConfig, value: boolean) => void,
]

export interface IGameConfig {
    isMute: boolean
    isTurboMode: boolean
    translations: Translations | null
}